/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Modal,
  Box,
  Typography,
  Grid,
  Button
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import axiosInstance from 'src/utils/axios';
import { useRouter } from 'src/routes/hook';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { booking } from 'src/redux/slices/bookingSlice';
import { useSnackbar } from 'notistack';

const TrackBookingModal = ({ open, onClose }) => {
  const router = useRouter();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [timer, setTimer] = useState(0);
  const [verificationId, setVerificationId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phoneNumber: Yup.string().required('Phone Number is required'),
    trackingId: Yup.string(),
    otp: Yup.string().when('otpSent', {
      is: true,
      then: Yup.string().required('OTP is required'),
    }),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    trackingId: '',
    otp: ''
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors  },
  } = methods;

  const resetForm = () => {
    methods.reset(defaultValues);
    setOtpSent(false);
    setOtpVerified(false);
    setTimer(0);
  };

  const values = watch();

  const startTimer = () => {
    setTimer(30);
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const sendOtp = async(phoneNumber) => {
    try{
      const inputData = {
        phoneNumber : `+${phoneNumber}`
      }
      startTimer();
      setValue("otp",'');
      const res = await axiosInstance.post('/track-booking/send-otp', inputData);
      enqueueSnackbar(res?.data?.message, { variant:res?.data?.success ?  'success' : 'error' });
      if(res?.data?.success)
      {
        setOtpSent(true);
        setVerificationId(res?.data?.verificationSid);
      }
    }catch(error){
      enqueueSnackbar(
        typeof error === 'string'
          ? error
          : error?.error?.message
          ? error?.error?.message
          : error?.message,
        {
          variant: 'error',
        }
      );
    }
  };

  const handleOtpVerification = async () => {
    try{
      const inputData = {
        id : verificationId,
        code : values.otp,
        phoneNumber : `+${values.phoneNumber}`
      }

      const res = await axiosInstance.post('/track-booking/verify-otp', inputData);
      enqueueSnackbar(res?.data?.message, { variant:res?.data?.success ?  'success' : 'error' });
      if(res?.data?.success){
        setOtpVerified(true);
      }
    }catch(error){
      enqueueSnackbar(
        typeof error === 'string'
          ? error
          : error?.error?.message
          ? error?.error?.message
          : error?.message,
        {
          variant: 'error',
        }
      );
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    try {
      console.log('Form data:', formData);
      const inputData = {
        phoneNumber: `+${formData.phoneNumber}`,
        firstName: formData.firstName ? formData.firstName : '',
        lastName: formData.lastName ? formData.lastName : '',
        trackingId: formData.trackingId ? formData.trackingId : '',
      };

      const response = await axiosInstance.post('/track-booking', inputData);
      if (response?.data?.booking) {
        dispatch(booking(response?.data?.booking));
        navigate(`/bookings`);
        handleClose();  // Call the close function here
      }
    } catch (error) {
      console.error('Submission error:', error);
    }
  });

  const handleClose = () => {
    onClose();
    resetForm();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose} // Use handleClose instead of just onClose
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          width: 400,
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          mx: 'auto',
          mt: otpSent ? '1%' : '5%',
          transition: 'all 0.3s',
          boxShadow: 24
        }}
      >
        <Typography id="modal-title" sx={{ textAlign: 'center' }} variant="h6" component="h2">
          Track Booking
        </Typography>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box component="div" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <Typography sx={{ marginBottom: '10px' }} variant='body1'>First Name (Optional)</Typography>
                <RHFTextField name='firstName' placeholder="Enter your first name" />
              </Grid> */}
              {/* <Grid item xs={12}>
                <Typography sx={{ marginBottom: '10px' }} variant='body1'>Last Name (Optional)</Typography>
                <RHFTextField name='lastName' placeholder="Enter your last name" />
              </Grid> */}
              <Grid item xs={12}>
                <Typography sx={{ marginBottom: '10px' }} variant="body1">
                  Enter phone number
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="phoneNumber"
                    control={methods.control}
                    render={({ field }) => (
                      <PhoneInput
                        country="in"
                        value={field.value}
                        onChange={(phoneNumber) => field.onChange(phoneNumber)}
                        inputProps={{
                          autoComplete: 'on',
                          autoFocus: true,
                          disabled: otpVerified,
                        }}
                        inputStyle={{
                          width: '100%',
                          height: '40px',
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    onClick={() => sendOtp(values.phoneNumber)}
                    disabled={(!values.phoneNumber && !otpVerified ) || (otpSent && timer > 0)} // Disable if phoneNumber is empty or if otpSent and timer > 0
                    sx={{ ml: 1, height: '40px', minWidth: '100px' }}
                  >
                    {timer > 0 ? `Resend in ${timer}s` : 'Get Code'}
                </Button>
                </Box>
                {errors.phoneNumber && (
                  <Box sx={{ color: '#FF5630', fontSize: '0.75rem' }}>
                    {errors.phoneNumber.message}
                  </Box>
                )}
              </Grid>
              {otpSent && (
                <Grid item xs={12}>
                  <Typography sx={{ marginBottom: '10px' }} variant="body1">
                    Enter OTP
                  </Typography>
                  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <RHFTextField
                      sx={{ flexGrow: 1 }}
                      name="otp"
                      placeholder="Enter the OTP"
                      disabled={otpVerified}
                      fullWidth
                      inputProps={{
                        inputMode: 'numeric',
                        maxLength: 6,
                        onKeyPress: (event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        },
                        onChange: (event) => {
                          if (event.target.value.length > 6) {
                            event.target.value = event.target.value.slice(0, 6);
                          }
                        },
                      }}
                    />
                    <Box sx={{ marginLeft: '8px', position: 'absolute', right: 10 }}>
                      <Typography
                        sx={{
                          color: values.otp.length === 6 ? '#001083' : 'gray', // Change color based on OTP length
                          cursor: values.otp.length === 6 ? 'pointer' : 'not-allowed', // Disable pointer events if OTP is not 6 digits
                          textDecoration: otpVerified || values.otp.length !== 6 ? 'none' : 'underline', // Remove underline if OTP is not 6 digits
                          fontWeight: otpVerified || values.otp.length !== 6 ? 'normal' : 'bold',
                          fontSize: '12px',
                          pointerEvents: values.otp.length === 6 ? 'auto' : 'none' // Disable clicks if OTP is not 6 digits
                        }}
                        variant="body2"
                        onClick={values.otp.length === 6 && !otpVerified ? handleOtpVerification : undefined}
                      >
                        Verify OTP
                      </Typography>
                    </Box>
                  </Box>
                  {errors.otp && (
                    <Box sx={{ color: '#FF5630', fontSize: '0.75rem', marginTop: '4px' }}>
                      {errors.otp.message}
                    </Box>
                  )}
                </Grid>
              )}
              {/* <Grid item xs={12}>
                <Typography sx={{ marginBottom: '10px' }} variant='body1'>Tracking Id (Optional)</Typography>
                <RHFTextField name='trackingId' placeholder="Enter booking tracking Id" />
              </Grid> */}
              <Grid justifyContent='center' item xs={12} sx={{ mt: 2 }}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!otpVerified || isSubmitting} // Disable when otpVerified is false or form is submitting
                style={{
                  backgroundColor: otpVerified ? '#001083' : 'gray', // Change color if disabled
                  height: '40px',
                }}
              >
                Look Up
              </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  );
};

TrackBookingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TrackBookingModal;

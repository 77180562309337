import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/me',
    login: '/verify-otp-login',
    register: '/api/auth/register',
  },
   user: {
    list: '/api/users/list',
    notifications: '/notifications',
    filterList: (filter) => `/api/users/list?${filter}`,
    details: (id) => `/api/users/${id}`,
    search: '/api/user/search',
    getDashboradCounts: '/getDashboardCounts',
  },
  lotOwnerPlan: {
    list: '/lot-owner-plans',
    details: (id) => `/lot-owner-plans/${id}`,
  },
  lotOwnerEnquiry: {
    list: '/lot-owner-enquiries',
    // details: (id) => `/lot-owner-plans/${id}`,
  },
  parkingFacilities: {
    list: '/parking-facilities',
  },
  parkingLocationType: {
    list: '/parking-location-types',
  },
  parkingLocation: {
    list: '/parking-locations',
    details: (id) => `/parking-locations/${id}`,
  },
  ownerParkingLocation: {
    list: (userId) => `/owner-parking-locations/${userId}`,
  },
  parkings:{
    list: '/parkings',
    details:(id) => `/parkings/${id}`,
  },
  taxAndFees:{
    details:(id) => `/tax-and-fees/${id}`,
  },
  bookings:{
    list: '/bookings',
    details:(id) => `/bookings/${id}`,
  },
  shuttleFacilities: {
    list: '/shuttle-facilities',
  },
  shuttleLocation:{
    list: '/shuttle-locations',
    details:(id) => `/shuttle-locations/${id}`,
  },
  shuttles:{
    list: '/shuttles',
    details:(id) => `/shuttles/${id}`,
  },
  riders:{
    list: '/riders',
    details:(id) => `/riders/${id}`,
  },
  lotOwnerShuttles:{
    list: '/owner-shuttle',
    details:(id) => `/owner-shuttle/${id}`,
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

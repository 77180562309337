import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { RolesAuthRoute } from '../hook/RolesAuthRoute';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const IndexPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// LOT OWNER PLAN
const LotOwnerPlanListPage = lazy(() => import('src/pages/dashboard/lotOwnerPlan/list'));
const LotOwnerPlanCreatePage = lazy(() => import('src/pages/dashboard/lotOwnerPlan/new'));
const LotOwnerPlanEditPage = lazy(() => import('src/pages/dashboard/lotOwnerPlan/edit'));
// LOT OWNER ENQUIRY
const LotOwnerEnquiryListPage = lazy(() => import('src/pages/dashboard/LotOwnerEnquiry/list'));
// PARKING FACILITY
const ParkingFacility = lazy(() => import('src/pages/dashboard/ParkingFacility/list'));
const CreateParkingFacility = lazy(() => import('src/pages/dashboard/ParkingFacility/new'));
// PARKING FACILITY
const ParkingLocationType = lazy(() => import('src/pages/dashboard/parkingLocationType/list'));
const CreateParkingLocationType = lazy(() => import('src/pages/dashboard/parkingLocationType/new'));
// PARKING LOCATIONS
const ParkingLocations = lazy(() => import('src/pages/dashboard/parkingLocation/list'));
const CreateParkingLocations = lazy(() => import('src/pages/dashboard/parkingLocation/new'));
// OWNER PARKING LOCATIONS
const OwnerParkingLocations = lazy(() => import('src/pages/dashboard/ownerParkingLocations/list'));
const OwnerCreateParkingLocations = lazy(() => import('src/pages/dashboard/ownerParkingLocations/new'));
// PARKINGS
const ParkingList = lazy(() => import('src/pages/dashboard/parkings/list'));
const ParkingCreatePage = lazy(() => import('src/pages/dashboard/parkings/new'));
// Destination pick Up points
const DestinationPickUpPage = lazy(() => import('src/pages/dashboard/destinationPickups/list'));
const Destinations = lazy(() => import('src/pages/dashboard/destinationPickups/destinations'));
// Lot owner destination pickups
const LotOwnerDestinationPickUpPage = lazy(() => import('src/pages/dashboard/lotOwnerDestinationPickups/list'));
const LotOwnerDestinations = lazy(() => import('src/pages/dashboard/lotOwnerDestinationPickups/destination'));
// Shuttle Rider Assignment list
const ShuttleRiderListPage = lazy(() => import('src/pages/dashboard/Shuttle-rider/list'));
// OWNER PARKINGS
const OwnerParkingList = lazy(() => import('src/pages/dashboard/owner-parkings/list'));
const OwnerParkingCreatePage = lazy(() => import('src/pages/dashboard/owner-parkings/new'));
// Bookings
const BookingList = lazy(() => import('src/pages/dashboard/booking/list'));
// Shuttle
const ShuttleListPage = lazy(() => import('src/pages/dashboard/shuttle/list'));
const ShuttleCreatePage = lazy(() => import('src/pages/dashboard/shuttle/new'));
// Shuttle
const RiderListPage = lazy(() => import('src/pages/dashboard/rider/list'));
const RiderCreatePage = lazy(() => import('src/pages/dashboard/rider/new'));
// Lot Owner Shuttle
const LotOwnerShuttleListPage = lazy(() => import('src/pages/dashboard/lotOwnerShuttle/list'));
const LotOwnerShuttleCreatePage = lazy(() => import('src/pages/dashboard/lotOwnerShuttle/new'));
// OWNER Bookings
const OwnerBookingList = lazy(() => import('src/pages/dashboard/owner-booking/list'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// QR-code-scanner
const BookingScanner = lazy(() => import('src/pages/dashboard/BookingScanner/view'));
// Lot-Owner_settlements
const LotOwnerSettlements = lazy(() => import('src/pages/dashboard/lot_owner_settlements/list'));
const LotOwnerBookingsListPage = lazy(() => import('src/pages/dashboard/lot_owner_settlements/reports'));
// lotowner-settlements
const SettlementPage = lazy(() => import('src/pages/dashboard/settlements/list'));
const LotOwnerSettlementPage = lazy(() => import('src/pages/dashboard/settlement-lotOwner/settlement'));
// Tax-and-fees
const TaxAndFees = lazy(() => import('src/pages/dashboard/refundPolicy/list'));
// RiderBookings
const RiderBookings = lazy(() => import('src/pages/dashboard/Rider-Bookings/list'))
// Lot Owner Admin
const LotOwnerAdminListPage = lazy(() => import('src/pages/dashboard/lotOwnerAdmin/list'));
const LotOwnerAdminCreatePage = lazy(() => import('src/pages/dashboard/lotOwnerAdmin/new'));
const LotOwwnerAdminParkingAssignmentPage = lazy(() => import('src/pages/dashboard/lotOwnerAdmin/parkingAssignment'));
// Lot owner Riders
const LotOwnerRiderPage = lazy(() => import('src/pages/dashboard/lotOwnerRider/list'));
const LotOwnerRiderCreatePage = lazy(() => import('src/pages/dashboard/lotOwnerRider/new'));
// admin-refund-policy
const AdminRefundPolicyListPage = lazy(() => import('src/pages/dashboard/Admin-reffund-policy/list'));
const AdminRefundPolicyPage = lazy(() => import('src/pages/dashboard/Admin-reffund-policy/new'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      {
        path: 'lotOwnerPlan',
        children: [
          { element: <LotOwnerPlanListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerPlanListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerPlanCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerPlanEditPage />
              </RolesAuthRoute>
            ),
          },
        ],
      },
      {
        path: 'lotOwnerEnquiry',
        children: [
          { element: <LotOwnerEnquiryListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerEnquiryListPage />
              </RolesAuthRoute>
            ),
          },
          // {
          //   path: 'new',
          //   element: (
          //     <RolesAuthRoute roles={['admin']}>
          //       <LotOwnerPlanCreatePage />
          //     </RolesAuthRoute>
          //   ),
          // },
          // {
          //   path: ':id/edit',
          //   element: (
          //     <RolesAuthRoute roles={['admin']}>
          //       <LotOwnerPlanEditPage />
          //     </RolesAuthRoute>
          //   ),
          // },
        ],
      },
      {
        path: 'parkingFacility',
        children: [
          { element: <ParkingFacility />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ParkingFacility />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <CreateParkingFacility />
              </RolesAuthRoute>
            ),
          },
          // {
          //   path: ':id/edit',
          //   element: (
          //     <RolesAuthRoute roles={['admin']}>
          //       <LotOwnerPlanEditPage />
          //     </RolesAuthRoute>
          //   ),
          // },
        ],
      },
      {
        path: 'parkingLocationType',
        children: [
          { element: <ParkingLocationType />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ParkingLocationType />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <CreateParkingLocationType />
              </RolesAuthRoute>
            ),
          },
          // {
          //   path: ':id/edit',
          //   element: (
          //     <RolesAuthRoute roles={['admin']}>
          //       <LotOwnerPlanEditPage />
          //     </RolesAuthRoute>
          //   ),
          // },
        ],
      },
      {
        path: 'parkings',
        children: [
          { element: <ParkingList />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ParkingList />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ParkingCreatePage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'destinationPickups',
        children: [
          { element: <DestinationPickUpPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin',]}>
                <DestinationPickUpPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'destinations/:parkingId',
            element: (
              <RolesAuthRoute roles={['admin',]}>
                <Destinations />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'lotOwnerDestinationPickups',
        children: [
          { element: <LotOwnerDestinationPickUpPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <LotOwnerDestinationPickUpPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'lotOwnerDestinations/:parkingId',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <LotOwnerDestinations />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'lotOwnerSettlements',
        children: [
          { element: <lotOwnerSettlements />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerSettlements />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'reports/:lotOwnerId',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <LotOwnerBookingsListPage/>
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'settlements',
        children: [
          { element: <SettlementPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <LotOwnerSettlementPage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'shuttleRider',
        children: [
          { element: <ShuttleRiderListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <ShuttleRiderListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <ParkingCreatePage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'bookings',
        children: [
          { element: <BookingList />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <BookingList />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'shuttle',
        children: [
          { element: <ShuttleListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ShuttleListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ShuttleCreatePage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'rider',
        children: [
          { element: <RiderListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <RiderListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <RiderCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'rider-bookings/:riderId',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <RiderBookings />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'lotOwnerRider',
        children: [
          { element: <RiderListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <LotOwnerRiderPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
                <LotOwnerRiderCreatePage />
              </RolesAuthRoute>
            ),
          },
          // {
          //   path: 'rider-bookings/:riderId',
          //   element: (
          //     <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
          //       <RiderBookings />
          //     </RolesAuthRoute>
          //   ),
          // },
        ]
      },
      {
        path: 'lotOwnerShuttle',
        children: [
          { element: <LotOwnerShuttleListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <LotOwnerShuttleListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <LotOwnerShuttleCreatePage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'lotOwnerAdmin',
        children: [
          { element: <LotOwnerAdminListPage />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner']}>
                <LotOwnerAdminListPage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['lot_owner']}>
                <LotOwnerAdminCreatePage />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'assignParking/:adminId',
            element: (
              <RolesAuthRoute roles={['lot_owner']}>
                <LotOwwnerAdminParkingAssignmentPage />
              </RolesAuthRoute>
            ),
          }
        ]
      },
      {
        path: 'ownerBookings',
        children: [
          { element: <OwnerBookingList />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <OwnerBookingList />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'ownerParkings',
        children: [
          { element: <OwnerParkingList />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <OwnerParkingList />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['lot_owner', 'lot_owner_admin']}>
                <OwnerParkingCreatePage />
              </RolesAuthRoute>
            ),
          },
        ]
      },
      {
        path: 'parkingLocations',
        children: [
          { element: <ParkingLocations />, index: true },
          {
            path: 'list',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <ParkingLocations />
              </RolesAuthRoute>
            ),
          },
          {
            path: 'new',
            element: (
              <RolesAuthRoute roles={['admin']}>
                <CreateParkingLocations />
              </RolesAuthRoute>
            ),
          },
          // {
          //   path: ':id/edit',
          //   element: (
          //     <RolesAuthRoute roles={['admin']}>
          //       <LotOwnerPlanEditPage />
          //     </RolesAuthRoute>
          //   ),
          // },
        ],
      },
      { path: 'bookingScanner', 
        element: (
          <RolesAuthRoute roles={['admin', 'lot_owner', 'lot_owner_admin']}>
            <BookingScanner />
          </RolesAuthRoute>
        ), 
      },
      { path: 'refund-policy', 
        element: (
          <RolesAuthRoute roles={['admin', 'lot_owner']}>
            <TaxAndFees />
          </RolesAuthRoute>
        ), 
      },

      {
        path:'admin-refund-policy',
        children : [
          {
            path : 'list',
            element : (
              <RolesAuthRoute roles={['admin']}>
                <AdminRefundPolicyListPage />
              </RolesAuthRoute>
            )
          },
          {
            path : ':lotOwnerId/policy',
            element : (
              <RolesAuthRoute roles={['admin']}>
                <AdminRefundPolicyPage />
              </RolesAuthRoute>
            )
          }
        ]
      },

      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
